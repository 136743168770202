import React from "react";
import pageImage from "images/HomepageLogo.png";
import { DocPageLayout, ObjectBrowser, SEO } from "components";

const DocumentationObjectBrowser = ({ location }) => {
  const title = "ECL Documentation";
  const description = "Emerald Cloud Lab Object Documentation Browser";

  const searchParams = new URLSearchParams(location.search);
  const startExpanded = searchParams.get("toggles") === "open";

  return (
    <DocPageLayout headerText="Documentation Center">
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      <ObjectBrowser identifier="id:1ZA60vAxLN8M" />
    </DocPageLayout>
  );
};

export default DocumentationObjectBrowser;
